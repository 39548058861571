import Breadcrumbs from '@/components/breadcrumbs/index.vue';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {}
  },

}
